import TopAd from 'components/TopAd';
import HomeFeed from 'components/HomeFeed';
import Layout from 'components/Layout';
import Sidebar from 'components/Sidebar';
import Wrapper from 'components/Wrapper';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import SEO from 'utils/SEO';

const Home = styled.div`
	display: grid;
	width: 100%;
	${theme.media.large} {
		grid-template-areas: 'main side';
		grid-template-columns: minmax(0, 1fr) auto;
	}
	${theme.media.notlarge} {
		grid-template-areas: 'main' 'side';
		grid-template-columns: 100%;
	}
	grid-gap: ${theme.size(4)};
`;
const Main = styled.div`
	grid-area: main;
`;
const Side = styled.div`
	grid-area: side;
`;

export default props => {
	return (
		<Layout>
			<SEO />
			<Wrapper>
				<TopAd />
				<Home>
					<Main>
						<HomeFeed />
					</Main>
					<Side>
						<Sidebar />
					</Side>
				</Home>
			</Wrapper>
		</Layout>
	);
};
